import React from 'react'
import Layout from 'layouts/fr'
import styled from 'styled-components'
import design from 'design'
import {Title, Text, Row, Div, Box, Button, Input} from 'components'
import { display } from 'styled-system'
import {TryOrSchedule} from "containers/fr";
import StairlinShip from "images/StairlinShip8bitcopy.png";
import Island from "images/PaymentPortal8bitcopy.png";

const Ribbon = styled.div`
  width: 200px;
  background: ${design.color.primary};
  position: absolute;
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  ${display}
`
const RibbonMobile = styled.div`
  margin: -30px 0 24px 64px;
  z-index: 1;
  position: sticky;
  background: ${design.color.primary};
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  //line-height: 30px;
  transform: rotate(-20deg);
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    background: inherit;
    height: inherit;
    width: inherit;
    top: 0;
    left: 0;
    z-index: -1;
    transform: rotate(30deg);
  }
  &:after {
    transform: rotate(60deg);
  }
  ${display}
`

export default () => {
  return (
    <Layout>

      <Box position={'relative'} mb={[6,8]} mt={[6,7]} bg={['transparent','white']} boxShadow={['none','black']} p={[0,6]}>

        <Div overflow={'hidden'} position={'absolute'} top={0} right={0} width={200} height={200}>
          <Ribbon display={['none','block']}>Prochainement</Ribbon>
        </Div>
        <RibbonMobile display={['flex','none']}>Prochai-<br/>nement</RibbonMobile>

        <Div display={['none','block']} position={'absolute'} top={-40} left={-20}>
          <img src={StairlinShip} width={231} alt={''} />
        </Div>
        <Div display={['none','block']} position={'absolute'} bottom={-52} right={-32}>
          <img src={Island} width={274} alt={''} />
        </Div>

        <Div maxWidth={['none','55%']} margin={'0 auto'} textAlign={'center'}>
          <Title mb={[2,6]} fontSize={[6,8]}>Faites-vous payer rapidement. A chaque fois.</Title>
          <Text mb={[4,6]} fontSize={[3,6]}>Spécialement conçu pour les entreprises solo, le système de paiement Stairlin est intuitif, fiable et simple. Nous faisons tout le travail pour vous afin que vous puissiez commencer à accepter des paiements en un clic de souris.</Text>
          <Div mt={[0,6]} bg={['transparent']}>
            <form action="https://stairlin.us14.list-manage.com/subscribe/post?u=d61bf0db69f16c103c6148607&amp;id=fa894e5198" method="post">
              <Div display={['block','flex']}>
                <Input name="EMAIL" fontSize={[4,6]} p={[3,4]} placeholder={`Votre email`} /><Button width={['100%','auto']} type={'submit'} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Soyez prévenu</Button>
              </Div>
            </form>
            <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Inscrivez-vous à Stairlin et obtenez un accès anticipé au système de paiement. </Text>
          </Div>
        </Div>
      </Box>

    </Layout>
  )
}
